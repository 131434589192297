<template>
  <div class="wrapper flex-between flex-col" @click="openAudio">
    <h3 class="bottom res font-l">
      <i class="el-icon-bell font-xl mr-x"></i>
      <span>客房消息</span>
    </h3>

    <el-row>
      <el-col :span="11" class="bottom">
        <el-button
          type="danger"
          size="large"
          class="res"
          :class="{ sos: sos > 0 }"
          @click="showList('0')"
        >
          <div class="flex-between">
            <span>紧急呼叫</span>
            <span>{{ sos }}</span>
          </div>
        </el-button>
      </el-col>
      <el-col :span="11" :offset="2" class="bottom">
        <el-button
          type="success"
          size="large"
          class="res"
          @click="showList('2')"
        >
          <div class="flex-between">
            <span>请即清理</span>
            <span>{{ clean }}</span>
          </div>
        </el-button>
      </el-col>
      <el-col :span="11" class="bottom">
        <el-button
          type="primary"
          size="large"
          class="res"
          @click="showList('1')"
        >
          <div class="flex-between">
            <span>门牌离线</span>
            <span>{{ off }}</span>
          </div>
        </el-button>
      </el-col>
      <el-col :span="11" :offset="2" class="bottom">
        <el-button
          type="warning"
          size="large"
          class="res"
          @click="showList('3')"
        >
          <div class="flex-between">
            <span>门磁报警</span>
            <span>{{ warning }}</span>
          </div>
        </el-button>
      </el-col>
    </el-row>

    <!-- <dv-scroll-board
      v-if="config.data.length > 0"
      ref="scrollBoard"
      :config="config"
      style="width: 100%; height: 100%"
    /> -->

    <el-carousel
      v-if="carouselList.length > 0"
      style="width: 100%; height: 60vh"
      height="60vh"
      direction="vertical"
      :interval="5000"
      indicator-position="none"
    >
      <el-carousel-item v-for="(item, index) in carouselList" :key="index">
        <el-row v-for="temp in item" :key="temp.roomNo" class="row">
          <el-col :span="6">{{ temp[0] }}</el-col>
          <el-col :span="6">{{ temp[1] }}</el-col>
          <el-col :span="12" class="text-r">{{ temp[2] }}</el-col>
        </el-row>
      </el-carousel-item>
    </el-carousel>

    <!-- 报警列表 -->
    <div class="empty flex-grow flex-center" v-else>
      <p>—— 暂无数据 ——</p>
    </div>

    <el-drawer title="报警列表" :visible.sync="visible" size="50%">
      <el-tabs type="card" v-model="activeName">
        <el-tab-pane name="0">
          <span slot="label">
            <span class="text-v">紧急呼叫</span>
            <span v-if="sos > 0" class="badge">{{ sos }}</span>
          </span>
        </el-tab-pane>
        <el-tab-pane label="门牌离线" name="1">
          <span slot="label">
            <span class="text-v">门牌离线</span>
            <span v-if="off > 0" class="badge">{{ off }}</span>
          </span>
        </el-tab-pane>
        <el-tab-pane label="请即清理" name="2">
          <span slot="label">
            <span class="text-v">请即清理</span>
            <span v-if="clean > 0" class="badge">{{ clean }}</span>
          </span>
        </el-tab-pane>
        <el-tab-pane label="门磁报警" name="3">
          <span slot="label">
            <span class="text-v">门磁报警</span>
            <span v-if="warning > 0" class="badge">{{ warning }}</span>
          </span>
        </el-tab-pane>
      </el-tabs>

      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="buildingName" label="建筑名称" min-width="150">
        </el-table-column>
        <el-table-column prop="floorName" label="楼层" min-width="80">
        </el-table-column>
        <el-table-column prop="roomNo" label="房间/区域" min-width="100">
        </el-table-column>
        <el-table-column prop="status" label="报警类型" min-width="100">
          <template slot-scope="{ row }">{{ type[row.status] }}</template>
        </el-table-column>
        <el-table-column prop="startTime" label="时间" min-width="150">
        </el-table-column>
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "RoomMessage",

  data() {
    return {
      activeName: "0",
      tableData0: [],
      tableData1: [],
      tableData2: [],
      tableData3: [],
      carouselList: [], // 滚动列表数据

      sos: 0,
      clean: 0,
      off: 0,
      warning: 0,

      visible: false,
      // 滚动列表
      // config: {
      //   data: [],
      //   rowNum: 8,
      //   waitTime: 3000,
      //   columnWidth: [100, 90],
      //   carousel: "page",
      //   align: ["left", "left", "right"],
      //   oddRowBGC: "transparent",
      //   evenRowBGC: "rgba(255, 255, 255, 0.1)",
      // },

      type: {
        sos: "紧急呼叫",
        offline: "门牌离线",
        mur: "请即清理",
        mcbj: "门磁报警",
      },
    };
  },

  computed: {
    tableData() {
      return this[`tableData${this.activeName}`];
    },
  },

  created() {},

  methods: {
    loadData(data) {
      let sos = 0;
      let clean = 0;
      let off = 0;
      let warning = 0;
      const list = [];
      const tableData0 = [];
      const tableData1 = [];
      const tableData2 = [];
      const tableData3 = [];
      // 转换时间
      function msToTime(duration) {
        let seconds = Math.floor((duration / 1000) % 60),
          minutes = Math.floor((duration / (1000 * 60)) % 60),
          hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
          days = Math.floor(duration / (1000 * 60 * 60 * 24));

        let str = "";
        if (days > 0) {
          str += `${days}天`;
        }
        if (hours > 0) {
          str += `${hours}小时`;
        }
        if (minutes > 0 || hours > 0) {
          str += `${minutes}分`;
        }
        str += `${seconds}秒`;

        return str;
      }
      data.forEach((item) => {
        let time = "";
        if (item.startTime) {
          // let start = moment(item.startTime.replace(/-/gi, "/"));
          let start = moment(item.startTime);
          time = msToTime(new Date().getTime() - start);
        }

        list.push([
          item.roomNo,
          this.type[item.status],
          time,
          // "10天2小时56分20秒",
        ]);
        switch (item.status) {
          case "sos":
            tableData0.push(item);
            sos++;
            break;
          case "offline":
            tableData1.push(item);
            off++;
            break;
          case "mur":
            tableData2.push(item);
            clean++;
            break;
          default:
            tableData3.push(item);
            warning++;
        }
      });
      if (sos > this.sos) {
        this.$emit("play", true);
      } else if (sos === 0) {
        this.$emit("pause", "noTip");
      }
      this.sos = sos;
      this.clean = clean;
      this.off = off;
      this.warning = warning;
      this.tableData0 = tableData0;
      this.tableData1 = tableData1;
      this.tableData2 = tableData2;
      this.tableData3 = tableData3;
      this.carouselList = this.groupBy(list, 5);
    },
    // 拆分
    groupBy(arr, size) {
      let result = [];
      for (let i = 0; i < arr.length; i += size) {
        result.push(arr.slice(i, i + size));
      }
      return result;
    },

    showList(tab) {
      this.activeName = tab;
      this.visible = true;
    },

    openAudio(e) {
      console.log(e);
    },
  },
};
</script>
<style scoped lang="scss">
@keyframes shan {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.06);
  }
}
.wrapper {
  height: 100%;
  padding: 2vh;
  box-sizing: border-box;
  .res {
    box-sizing: border-box;
  }
  .bottom {
    padding-bottom: 2vh;
  }

  .sos {
    animation: shan 0.5s infinite;
  }
}

.badge {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
  min-width: 18px;
  padding: 2px;
  height: 18px;
  box-sizing: border-box;
  line-height: 12px;
  font-size: 12px;
  text-align: center;
  border: 1px solid #999;
  border-radius: 20px;
  color: #999;
}

.row {
  padding: 0 4px;
  $height: calc((50vh - 131px) / 5);
  height: $height;
  line-height: $height;
  &:nth-child(even) {
    background: rgba(255, 255, 255, 0.1);
  }
}
</style>
