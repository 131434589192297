var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.visible,
        title: "手动入住/退房",
        "close-on-click-modal": false,
        width: "650px",
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            inline: true,
            "label-width": "90px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "入住房间", prop: "roomNo" } },
            [
              _c("el-input", {
                staticClass: "input",
                attrs: { clearable: "", placeholder: "请输入房间号" },
                model: {
                  value: _vm.ruleForm.roomNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "roomNo", $$v)
                  },
                  expression: "ruleForm.roomNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "操作类型", prop: "status", required: "" } },
            [
              _c(
                "el-radio",
                {
                  attrs: { label: "checkin" },
                  on: { change: _vm.nameChange },
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                },
                [_vm._v("入住")]
              ),
              _c(
                "el-radio",
                {
                  attrs: { label: "checkout" },
                  on: { change: _vm.nameChange },
                  model: {
                    value: _vm.ruleForm.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "status", $$v)
                    },
                    expression: "ruleForm.status",
                  },
                },
                [_vm._v("退房")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "客人姓名", prop: "name" } },
            [
              _c("el-input", {
                staticClass: "input",
                attrs: {
                  clearable: "",
                  disabled: "",
                  placeholder: "请输入姓名",
                },
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号码", prop: "phone" } },
            [
              _c("el-input", {
                staticClass: "input",
                attrs: { clearable: "", placeholder: "请输入手机号" },
                model: {
                  value: _vm.ruleForm.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "phone", $$v)
                  },
                  expression: "ruleForm.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "入住时间", prop: "arr" } },
            [
              _c("el-date-picker", {
                staticClass: "input",
                attrs: {
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择日期时间",
                },
                model: {
                  value: _vm.ruleForm.arr,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "arr", $$v)
                  },
                  expression: "ruleForm.arr",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "退房时间", prop: "dep" } },
            [
              _c("el-date-picker", {
                staticClass: "input",
                attrs: {
                  type: "datetime",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  placeholder: "选择日期时间",
                },
                model: {
                  value: _vm.ruleForm.dep,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "dep", $$v)
                  },
                  expression: "ruleForm.dep",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "footer",
        {
          staticClass: "flex-between",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("p", { staticClass: "gray" }, [
            _vm._v("注：此功能仅在PMS数据同步异常时（紧急情况下）操作"),
          ]),
          _c(
            "div",
            [
              _c(
                "el-button",
                { attrs: { size: "large" }, on: { click: _vm.close } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "large" },
                  on: { click: _vm.submit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }