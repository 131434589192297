<template>
  <el-dialog
    title="季节模式"
    width="840px"
    top="5vh"
    :visible="visible"
    :before-close="handleClose"
    @open="open"
  >
    <div class="wrapper">
      <div class="mb-3x">
        <span class="mr-3x">选择季节：</span>
        <el-radio v-model="ruleForm.seasonal" :label="1">夏季模式</el-radio>
        <el-radio v-model="ruleForm.seasonal" :label="2">冬季模式</el-radio>
      </div>

      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="90px"
        label-position="left"
      >
        <!-- 夏季模式 -->
        <template v-if="ruleForm.seasonal === 1">
          <!-- 时段设置 -->
          <el-form-item label="白天时段：">
            <el-time-picker
              style="width: 180px"
              v-model="ruleForm.summerConfig.dayBegin"
              placeholder="请选择开始时间"
              value-format="timestamp"
              :clearable="false"
              @change="timeChange('summerConfig')"
            >
            </el-time-picker>
            至
            <el-time-picker
              style="width: 180px"
              v-model="ruleForm.summerConfig.dayEnd"
              placeholder="请选择结束时间"
              value-format="timestamp"
              :clearable="false"
              @change="timeChange('summerConfig')"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="晚上时段：">
            <el-time-picker
              style="width: 180px"
              v-model="ruleForm.summerConfig.nightBegin"
              placeholder="请选择开始时间"
              disabled
            >
            </el-time-picker>
            至
            <el-time-picker
              style="width: 180px"
              v-model="ruleForm.summerConfig.nightEnd"
              placeholder="请选择结束时间"
              disabled
            >
            </el-time-picker>
          </el-form-item>
          <!-- 温度设置 -->
          <div class="flex-start">
            <p class="mt-x mr-2x align-start">已租房间温度：</p>
            <div>
              <el-form-item label="开关" label-width="40px">
                <el-radio-group
                  v-model="ruleForm.summerConfig.hireSwitch"
                  @change="
                    airSwitchChange($event, 'summerConfig', 'hireTemperature')
                  "
                >
                  <el-radio-button :label="1">开</el-radio-button>
                  <el-radio-button :label="0">关</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <template v-if="ruleForm.summerConfig.hireSwitch">
                <el-form-item
                  label="模式"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-radio-group v-model="ruleForm.summerConfig.hireModel">
                    <el-radio-button :label="1">制冷</el-radio-button>
                    <el-radio-button :label="2">制暖</el-radio-button>
                    <el-radio-button :label="0">送风</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="风速"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-radio-group v-model="ruleForm.summerConfig.hireWindSpeed">
                    <el-radio-button :label="3">自动</el-radio-button>
                    <el-radio-button :label="0">低速</el-radio-button>
                    <el-radio-button :label="1">中速</el-radio-button>
                    <el-radio-button :label="2">高速</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="温度"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-input-number
                    v-model="ruleForm.summerConfig.hireTemperature"
                    :min="16"
                    :max="30"
                    label="请输入温度"
                  ></el-input-number>
                </el-form-item>
              </template>
            </div>
          </div>
          <div class="flex-start">
            <p class="mt-x mr-2x align-start">待租房间温度：</p>
            <div>
              <el-form-item label="开关" label-width="40px">
                <el-radio-group
                  v-model="ruleForm.summerConfig.unHireSwitch"
                  @change="
                    airSwitchChange($event, 'summerConfig', 'unhireTemperature')
                  "
                >
                  <el-radio-button :label="1">开</el-radio-button>
                  <el-radio-button :label="0">关</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <template v-if="ruleForm.summerConfig.unHireSwitch">
                <el-form-item
                  label="模式"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-radio-group v-model="ruleForm.summerConfig.unhireModel">
                    <el-radio-button :label="1">制冷</el-radio-button>
                    <el-radio-button :label="2">制暖</el-radio-button>
                    <el-radio-button :label="0">送风</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="风速"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-radio-group
                    v-model="ruleForm.summerConfig.unhireWindSpeed"
                  >
                    <el-radio-button :label="3">自动</el-radio-button>
                    <el-radio-button :label="0">低速</el-radio-button>
                    <el-radio-button :label="1">中速</el-radio-button>
                    <el-radio-button :label="2">高速</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="温度"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-input-number
                    v-model="ruleForm.summerConfig.unhireTemperature"
                    :min="16"
                    :max="30"
                    label="请输入温度"
                  ></el-input-number>
                </el-form-item>
              </template>
            </div>
          </div>
        </template>
        <!-- 冬季模式 -->
        <template v-else>
          <!-- 时段设置 -->
          <el-form-item label="白天时段：">
            <el-time-picker
              style="width: 180px"
              v-model="ruleForm.winterConfig.dayBegin"
              placeholder="请选择开始时间"
              value-format="timestamp"
              :clearable="false"
              @change="timeChange('winterConfig')"
            >
            </el-time-picker>
            至
            <el-time-picker
              style="width: 180px"
              v-model="ruleForm.winterConfig.dayEnd"
              placeholder="请选择结束时间"
              value-format="timestamp"
              :clearable="false"
              @change="timeChange('winterConfig')"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="晚上时段：">
            <el-time-picker
              style="width: 180px"
              v-model="ruleForm.winterConfig.nightBegin"
              placeholder="请选择开始时间"
              disabled
            >
            </el-time-picker>
            至
            <el-time-picker
              style="width: 180px"
              v-model="ruleForm.winterConfig.nightEnd"
              placeholder="请选择结束时间"
              disabled
            >
            </el-time-picker>
          </el-form-item>
          <!-- 温度设置 -->
          <div class="flex-start">
            <p class="mt-x mr-2x align-start">已租房间温度：</p>
            <div>
              <el-form-item label="开关" label-width="40px">
                <el-radio-group
                  v-model="ruleForm.winterConfig.hireSwitch"
                  @change="
                    airSwitchChange($event, 'winterConfig', 'hireTemperature')
                  "
                >
                  <el-radio-button :label="1">开</el-radio-button>
                  <el-radio-button :label="0">关</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <template v-if="ruleForm.winterConfig.hireSwitch">
                <el-form-item
                  label="模式"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-radio-group v-model="ruleForm.winterConfig.hireModel">
                    <el-radio-button :label="1">制冷</el-radio-button>
                    <el-radio-button :label="2">制暖</el-radio-button>
                    <el-radio-button :label="0">送风</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="风速"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-radio-group v-model="ruleForm.winterConfig.hireWindSpeed">
                    <el-radio-button :label="3">自动</el-radio-button>
                    <el-radio-button :label="0">低速</el-radio-button>
                    <el-radio-button :label="1">中速</el-radio-button>
                    <el-radio-button :label="2">高速</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="温度"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-input-number
                    v-model="ruleForm.winterConfig.hireTemperature"
                    :min="16"
                    :max="30"
                    label="请输入温度"
                  ></el-input-number>
                </el-form-item>
              </template>
            </div>
          </div>
          <div class="flex-start">
            <p class="mt-x mr-2x align-start">待租房间温度：</p>
            <div>
              <el-form-item label="开关" label-width="40px">
                <el-radio-group
                  v-model="ruleForm.winterConfig.unHireSwitch"
                  @change="
                    airSwitchChange($event, 'winterConfig', 'unhireTemperature')
                  "
                >
                  <el-radio-button :label="1">开</el-radio-button>
                  <el-radio-button :label="0">关</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <template v-if="ruleForm.winterConfig.unHireSwitch">
                <el-form-item
                  label="模式"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-radio-group v-model="ruleForm.winterConfig.unhireModel">
                    <el-radio-button :label="1">制冷</el-radio-button>
                    <el-radio-button :label="2">制暖</el-radio-button>
                    <el-radio-button :label="0">送风</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="风速"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-radio-group
                    v-model="ruleForm.winterConfig.unhireWindSpeed"
                  >
                    <el-radio-button :label="3">自动</el-radio-button>
                    <el-radio-button :label="0">低速</el-radio-button>
                    <el-radio-button :label="1">中速</el-radio-button>
                    <el-radio-button :label="2">高速</el-radio-button>
                  </el-radio-group>
                </el-form-item>
                <el-form-item
                  label="温度"
                  prop="name"
                  label-width="40px"
                  class="mr-2x"
                >
                  <el-input-number
                    v-model="ruleForm.winterConfig.unhireTemperature"
                    :min="16"
                    :max="30"
                    label="请输入温度"
                  ></el-input-number>
                </el-form-item>
              </template>
            </div>
          </div>
        </template>

        <!-- 停阀不停风 -->
        <el-form-item label="停阀不停风：" label-width="100px">
          <div class="flex-start">
            <el-switch v-model="ruleForm.onlyCloseValve"></el-switch>
            <p class="ml-2x gray">
              当空调运行到达设置温度时，关闭制冷/制热阀门，但不关闭送风
            </p>
          </div>
        </el-form-item>

        <!-- 无人断电配置 -->
        <el-form-item label="已租房间无人断电时段：" label-width="170px">
          <el-time-picker
            style="width: 159px"
            v-model="ruleForm.noCardTakeElectricityConfig.powerOffBeginTime"
            placeholder="请选择开始时间"
            value-format="timestamp"
            :clearable="false"
          >
          </el-time-picker>
          至
          <el-time-picker
            style="width: 159px"
            v-model="ruleForm.noCardTakeElectricityConfig.powerOffEndTime"
            placeholder="请选择结束时间"
            value-format="timestamp"
            :clearable="false"
          >
          </el-time-picker>
        </el-form-item>
        <el-form-item
          label="已租房间检测无人断电时长："
          label-width="200px"
          prop="noCardTakeElectricityConfig.powerOutageTime"
        >
          <el-input
            v-model="ruleForm.noCardTakeElectricityConfig.powerOutageTime"
            style="width: 200px"
            type="number"
            placeholder="请输入次数"
            clearable
          >
            <template slot="append">分钟</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="待租房间检测无人断电时长："
          prop="noCardTakeElectricityConfig.uncheckInPowerOutageTime"
          label-width="200px"
        >
          <el-input
            v-model="
              ruleForm.noCardTakeElectricityConfig.uncheckInPowerOutageTime
            "
            style="width: 200px"
            type="number"
            placeholder="请输入次数"
            clearable
          >
            <template slot="append">分钟</template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>

    <footer slot="footer">
      <el-button @click="handleClose" size="large">取 消</el-button>
      <el-button type="primary" size="large" v-if="loading" loading
        >保存中</el-button
      >
      <el-button type="primary" @click="ok" size="large" v-else
        >保存并更新房间</el-button
      >
    </footer>
  </el-dialog>
</template>

<script>
import { productAdd, productPut } from "../../../../api";
export default {
  name: "SeasonalPatternConfig",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    seasonal: {
      type: Number,
      default: 1,
    },
    editId: {
      type: String,
      default: "",
    },
    onlyCloseValve: {
      type: Boolean,
      default: false,
    },
    summerConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    winterConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    noCardTakeElectricityConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    const numberReg = /^[1-9]\d*$/;
    const checkNumberReg = (rule, value, callback) => {
      value && !numberReg.test(value)
        ? callback(new Error("请输入正整数"))
        : callback();
    };
    return {
      loading: false,
      // 夏季配置
      ruleForm: {
        seasonal: 1, //  1 夏季，2冬季
        // 夏季配置
        summerConfig: {
          dayBegin: 1677456000000, // 白天开始时间
          dayEnd: 1677491999000, // 白天结束时间
          nightBegin: 1677492000000, // 晚上开始时间
          nightEnd: 1677455999000, // 晚上结束时间
          hireModel: 1, // 出租模式 1-制冷，2-制热,0-通风
          hireWindSpeed: 3, // 出租风速 3-自动，2-高速，1-中速，0-低速
          hireTemperature: 24, // 出租温度
          unhireModel: 1, // 待租模式 1-制冷，2-制热,0-通风
          unhireWindSpeed: 3, // 待租风速 3-自动，2-高速，1-中速，0-低速
          unhireTemperature: 0, // 待租温度
          hireSwitch: 0, // 出租开关
          unHireSwitch: 0, // 待租开关
        },
        // 冬季配置
        winterConfig: {
          dayBegin: 1677456000000, // 白天开始时间
          dayEnd: 1677491999000, // 白天结束时间
          nightBegin: 1677492000000, // 晚上开始时间
          nightEnd: 1677455999000, // 晚上结束时间
          hireModel: 2, // 出租模式 1-制冷，2-制热,0-通风
          hireWindSpeed: 3, // 出租风速 3-自动，2-高速，1-中速，0-低速
          hireTemperature: 28, // 出租温度
          unhireModel: 2, // 待租模式 1-制冷，2-制热,0-通风
          unhireWindSpeed: 3, // 待租风速 3-自动，2-高速，1-中速，0-低速
          unhireTemperature: 0, // 待租温度
          hireSwitch: 0, // 出租开关
          unHireSwitch: 0, // 待租开关
        },
        // 无卡取电
        noCardTakeElectricityConfig: {
          powerOffBeginTime: 1677452400000, // 开始时间
          powerOffEndTime: 1677506399000, // 结束时间
          powerOutageTime: 30, // 已租房间检测无人断电时长
          uncheckInPowerOutageTime: 5, // 待租房间检测无人断电时长
        },
        onlyCloseValve: false, // 停阀不停风
      },

      rules: {
        "noCardTakeElectricityConfig.powerOutageTime": [
          { validator: checkNumberReg, trigger: "blur" },
        ],
        "noCardTakeElectricityConfig.uncheckInPowerOutageTime": [
          { validator: checkNumberReg, trigger: "blur" },
        ],
      },
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
  },

  created() {},

  methods: {
    // 回显数据
    open() {
      this.ruleForm.seasonal = this.seasonal;
      this.ruleForm.onlyCloseValve = this.onlyCloseValve;
      this.ruleForm.noCardTakeElectricityConfig =
        this.noCardTakeElectricityConfig;

      const fn = (name) => {
        for (let key in this.ruleForm[name]) {
          this.ruleForm[name][key] = this[name][key];
        }
        // 温度为0时空调为关
        if (this.ruleForm[name].hireTemperature === 0) {
          this.ruleForm[name].hireSwitch = 0;
        } else {
          this.ruleForm[name].hireSwitch = 1;
        }
        if (this.ruleForm[name].unhireTemperature === 0) {
          this.ruleForm[name].unHireSwitch = 0;
        } else {
          this.ruleForm[name].unHireSwitch = 1;
        }
      };
      fn("summerConfig");
      fn("winterConfig");
    },
    // 开关空调
    airSwitchChange(val, key1, key2) {
      if (val) {
        this.ruleForm[key1][key2] = 26;
      } else {
        this.ruleForm[key1][key2] = 0;
      }
    },

    handleClose() {
      this.$emit("update:visible", false);
    },
    // 保存
    ok() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let api = productAdd;
          const data = {
            shapeId: "1574352036712722440",
            shapeContent: JSON.stringify({
              hotelId: this.user.storeId,
              hotelName: this.user.storeName,
              summerConfig: this.ruleForm.summerConfig,
              winterConfig: this.ruleForm.winterConfig,
              // autoUpdate: this.autoUpdate,
              noCardTakeElectricityConfig:
                this.ruleForm.noCardTakeElectricityConfig,
              onlyCloseValve: this.ruleForm.onlyCloseValve,
            }),
          };
          if (this.editId) {
            api = productPut;
            data.id = this.editId;
          }
          this.loading = true;
          api(data)
            .then((res) => {
              this.handleClose();
              this.$emit("change", this.ruleForm, res.data);
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.$formError();
          return false;
        }
      });
    },

    timeChange(key) {
      this.ruleForm[key].nightBegin = this.ruleForm[key].dayEnd + 1000;
      this.ruleForm[key].nightEnd = this.ruleForm[key].dayBegin - 1000;
    },
  },
};
</script>
<style scoped>
.wrapper {
  height: 650px;
  overflow-y: auto;
}
@media screen and (max-height: 750px) {
  .wrapper {
    height: 500px;
    overflow-y: auto;
  }
}
@media screen and (max-height: 700px) {
  .wrapper {
    height: 340px;
    overflow-y: auto;
  }
}
</style>
