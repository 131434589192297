var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _vm.visible
      ? _c("div", { staticClass: "modal" }, [
          _c(
            "div",
            {
              staticClass: "window",
              class: [_vm.theme],
              style: { width: _vm.width },
            },
            [
              _c("header", { staticClass: "flex-between top" }, [
                _c("h2", [_vm._v(_vm._s(_vm.title))]),
                _c("i", {
                  staticClass: "el-icon-circle-close font-xxxl pointer",
                  on: { click: _vm.cancel },
                }),
              ]),
              _c("section", { staticClass: "ph-2x" }, [_vm._t("default")], 2),
              _vm._t("footer", function () {
                return [
                  _c(
                    "footer",
                    { staticClass: "flex-center p-2x mt-1x" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { plain: "", size: "large" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v("取消")]
                      ),
                      _vm.loading
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                size: "large",
                                loading: "",
                              },
                            },
                            [_vm._v("加载中")]
                          )
                        : _c(
                            "el-button",
                            {
                              attrs: { type: "primary", size: "large" },
                              on: { click: _vm.sure },
                            },
                            [_vm._v("确定")]
                          ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }