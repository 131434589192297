var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bg" },
    [
      _c(
        "header",
        { staticClass: "top flex-start" },
        [
          _c("the-header", {
            attrs: { playing: _vm.playing, muted: _vm.muted },
            on: {
              play: _vm.play,
              pause: _vm.pause,
              "open-command": _vm.openCommand,
              "room-search": _vm.roomSearch,
              update: _vm.loadChartData,
            },
          }),
        ],
        1
      ),
      _c("main", { staticClass: "flex-evenly main" }, [
        _c("aside", { staticClass: "left" }, [
          _c(
            "div",
            { staticClass: "top box" },
            [
              _c("seasonal-pattern", {
                ref: "seasonalPattern",
                on: { update: _vm.loadChartData },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom box" },
            [
              _c("room-message", {
                ref: "roomMessage",
                on: { play: _vm.play, pause: _vm.pause },
              }),
            ],
            1
          ),
        ]),
        _c("section", { staticClass: "right" }, [
          _c(
            "div",
            { staticClass: "buildings" },
            [
              _c("floor-room-item", {
                attrs: { list: _vm.buildingData },
                on: { open: _vm.roomMonitoring },
              }),
              _vm.buildingData.length === 0 ? _c("el-empty") : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "my-dialog",
        {
          attrs: { visible: _vm.visibleAir, title: "开空调" },
          on: {
            "update:visible": function ($event) {
              _vm.visibleAir = $event
            },
          },
        },
        [
          _c("floor-room-item", {
            attrs: { list: _vm.airData },
            on: { open: _vm.controlAir },
          }),
        ],
        1
      ),
      _c("room-control", {
        attrs: {
          visible: _vm.visibleRoomControl,
          room: _vm.tempRoom,
          "room-no": _vm.roomNo,
        },
        on: {
          "update:visible": function ($event) {
            _vm.visibleRoomControl = $event
          },
          close: _vm.loadChartData,
        },
      }),
      _c(
        "my-dialog-small",
        {
          attrs: {
            visible: _vm.visibleAirControl,
            width: "500px",
            theme: "black",
            title: _vm.tempRoom.roomNo,
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleAirControl = $event
            },
            cancel: _vm.loadChartData,
          },
        },
        [
          _vm._l(_vm.airs, function (item) {
            return _c("room-control-air", {
              key: item.deviceId,
              attrs: { data: item, response: false },
            })
          }),
          _c("footer", { attrs: { slot: "footer" }, slot: "footer" }),
        ],
        2
      ),
      _c(
        "my-dialog-small",
        {
          attrs: { title: "提示", visible: _vm.showClickAudio },
          on: {
            "update:visible": function ($event) {
              _vm.showClickAudio = $event
            },
            cancel: _vm.openAudio,
          },
        },
        [
          _c("p", [_vm._v("刷新页面后需重新授权开启紧急呼叫语音报警功能！")]),
          _c(
            "footer",
            {
              staticClass: "flex-center pv-2x",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "large" },
                  on: { click: _vm.openAudio },
                },
                [_vm._v("授权开启")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("controls-preset", {
        attrs: { visible: _vm.showControlsPreset },
        on: {
          "update:visible": function ($event) {
            _vm.showControlsPreset = $event
          },
        },
      }),
      _c("controls-dialog", {
        attrs: {
          visible: _vm.showControls,
          "building-data": _vm.buildingData,
          "component-name": _vm.controlComponentName,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showControls = $event
          },
          change: _vm.handleControlChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }