<template>
  <div class="device flex-center">
    <div class="text-c">
      <!-- 窗帘关闭图片 -->

      <img
        v-if="switchstate === 'off'"
        src="../../../../assets/img/curtain-close.svg"
        class="curtain-img"
      />
      <!-- 窗帘开启图片 -->

      <img
        v-else
        src="../../../../assets/img/curtain-open.svg"
        class="curtain-img"
      />

      <!-- 窗帘名称 -->
      <p class="font-l mv-4x">窗帘</p>

      <div class="flex-evenly">
        <!-- 开 -->
        <div title="打开" @click="toggleCurtain('on')">
          <img
            v-if="switchstate === 'on'"
            class="curtain-btn"
            src="../../../../assets/img/on-active.svg"
          />
          <img v-else class="curtain-btn" src="../../../../assets/img/on.svg" />
        </div>
        <!-- 暂停 -->
        <div title="暂停" @click="toggleCurtain('pause')">
          <img
            v-if="switchstate === 'pause'"
            class="curtain-btn"
            src="../../../../assets/img/pause-active.svg"
          />
          <img
            v-else
            class="curtain-btn"
            src="../../../../assets/img/pause.svg"
          />
        </div>
        <!-- 关闭 -->
        <div title="关闭" @click="toggleCurtain('off')">
          <img
            v-if="switchstate === 'off'"
            class="curtain-btn"
            src="../../../../assets/img/off-active.svg"
          />
          <img
            v-else
            class="curtain-btn"
            src="../../../../assets/img/off.svg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ControlsCurtain",

  data() {
    return {
      switchstate: "on",
    };
  },

  mounted() {
    this.change();
  },

  methods: {
    toggleCurtain(val) {
      this.switchstate = val;
      this.change();
    },

    change() {
      this.$emit("change", {
        deviceType: "curtain", // 设备类型 aircondition -> 空调  curtain -> 窗帘  fan -> 排气扇
        switchstate: this.switchstate,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.device {
  height: 316px;
  border-radius: 20px;
  border-image: linear-gradient(
      154deg,
      rgba(86, 85, 85, 0.25) 43%,
      rgba(0, 0, 0, 0) 101%
    )
    1;

  .curtain-img {
    width: 100px;
    height: 100px;
  }
  .curtain-btn {
    margin: 0 8px;
    width: 64px;
    height: 64px;
    cursor: pointer;
  }

  .btn {
    cursor: pointer;
  }

  .device-name {
    margin: 32px 0;
    font-size: 16px;
  }
}

@media screen and (max-height: 750px) {
  .device {
    height: 316px;
  }
}
</style>
