<template>
  <RoomControlAir
    v-if="loaded"
    :data="ruleForm"
    :auto-submit="false"
    @change="change"
  />
</template>

<script>
import RoomControlAir from "@/components/page/control/components/RoomControlAir";
import { cloudPreSettingAirGet } from "../../../../api";
export default {
  name: "ControlsAir",
  components: {
    RoomControlAir,
  },

  data() {
    return {
      ruleForm: {
        targetTemperature: 16,
        temperature: null,
        switchstate: "on",
        mode: "heat",
        windspeed: "auto",
      },
      loaded: false,
    };
  },

  created() {
    this.loadData();
  },

  methods: {
    loadData() {
      cloudPreSettingAirGet()
        .then(({ data }) => {
          if (data.data) {
            this.ruleForm.mode = data.data.mode;
            this.ruleForm.windspeed = data.data.speed;
            this.ruleForm.targetTemperature = data.data.temperature;
          } else {
            this.$message.warning("未配置空调预设参数");
          }
          this.loaded = true;
          this.change(this.ruleForm);
        })
        .catch((e) => console.error(e));
    },

    change(val) {
      //   console.log(val);
      this.$emit("change", {
        deviceType: "aircondition", // 设备类型 aircondition -> 空调  curtain -> 窗帘  fan -> 排气扇
        ...val,
      });
    },
  },
};
</script>
<style scoped></style>
