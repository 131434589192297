import { Message } from "element-ui";

/**
 *@name:表单校验
 */
// 电话包括座机正则
export const telReg =
  /(^\d{10,12}$)|((^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$))|(^[0-9]{3,4}[-][0-9]{7,8})/;

// 手机号正则匹配
export const phoneReg = /(^1[3|4|5|6|7|8|9]\d{9}$)|(^09\d{8}$)/;

// 保留1位小数的正则（适用于验证金额）
export const fix1Reg =
  /^(([1-9][0-9]*)|[0]|(([0]\.\d{1}|[1-9][0-9]*\.\d{1})))$/;

// 保留2位小数的正则（适用于验证金额）
export const fix2Reg =
  /^(([1-9][0-9]*)|[0]|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;

// 正整数匹配
export const numberReg = /^[1-9]\d*$/;

// 0或正整数(含0)
export const integerReg = /^([1-9]\d*|[0]{1,1})$/;

// 匹配0-100正整数
export const integerOneToHundred = /^([1-9]\d?|100|[0]{1})$/;

// 匹配英文字母
export const enReg = /^[\w\s]+$/g;

// 匹配英文单词，只能包含字母或空格
export const wordReg = /^[\b(\w+)\b(\s+\b\w+\b)]+$/i;

// 匹配英文字母或数字
export const enOrNumberReg = /^[a-zA-Z0-9]+$/;

//匹配负数
export const negativeReg = /^-([1-9]\d*(\.\d+)?|0\.\d*[1-9])$/;

//匹配邮箱
export const emailReg =
  /^[A-Za-z0-9._\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;

//匹配URL
export const urlReg =
  /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*$/;

//匹配连贯名称
export const nameReg = /^[a-zA-Z0-9_\-\u4e00-\u9fa5]+$/;

//匹配连贯名称
export const textReg =
  /^[a-zA-Z0-9\u4e00-\u9fa5`~!@%^*()_\\/\-+=<>?:"{}|,.;'[\]·！￥¥（）—《》？：“”【】、；‘，。#$&\n\s]+$/;

// 0-100正整数
export const numberRatioSize = (rule, value, callback) => {
  !integerOneToHundred.test(value)
    ? callback(new Error("请输入0-100的正整数"))
    : callback();
};

// 验证座机和手机号
export const checkTel = (rule, value, callback) => {
  if (value && !telReg.test(value)) {
    callback(new Error("请输入正确的电话号码"));
  } else {
    callback();
  }
};

// 验证手机号
export const checkPhone = (rule, value, callback) => {
  if (value && !phoneReg.test(value)) {
    callback(new Error("手机号码为11位数字，不包含空格和特殊字符"));
  } else {
    callback();
  }
};
// 验证邮箱
export const checkEmail = (rule, value, callback) => {
  if (value && !emailReg.test(value)) {
    callback(new Error("请输入正确的邮箱地址，不包含空格和特殊字符"));
  } else {
    callback();
  }
};

// 校验名称-input
export const checkName = (rule, value, callback) => {
  if (value !== "" && !nameReg.test(value)) {
    callback(new Error("请输入连贯的文字，不包含空格"));
  } else {
    callback();
  }
};

// 匹配合法字符-textarea(含常规标点符号)
export const checkText = (rule, value, callback) => {
  if (!value || textReg.test(value)) {
    callback();
  } else {
    callback(new Error("不能包含表情符等特殊文字"));
  }
};

// 匹配数字，只能为0以上的自然数(包含小数)
export const checkNumber = (rule, value, callback) => {
  const NumReg = /^[+]{0,1}(\d+)$|^[+]{0,1}(\d+\.\d+)$/;
  value && !NumReg.test(value)
    ? callback(new Error("请输入0以上的数字"))
    : callback();
};

// 匹配充值金额，0以上的，两位小数内的
export const RechargeMoney = (rule, value, callback) => {
  !value
    ? callback()
    : !fix2Reg.test(value)
    ? callback(new Error("请输入数字，最多保留两位小数"))
    : value > 99999999.99
    ? callback(new Error("输入的金额不能大于99999999.99"))
    : value <= 0
    ? callback(new Error("输入的金额不能小于或等于0"))
    : callback();
};

// 匹配金额，两位小数内的
export const checkMoney = (rule, value, callback) => {
  !value
    ? callback()
    : !fix2Reg.test(value)
    ? callback(new Error("请输入数字，最多保留两位小数"))
    : value > 99999999.99
    ? callback(new Error("输入的金额不能大于99999999.99"))
    : callback();
};
// 匹配折扣，0.1至9.9，可保留一位小数
export const checkDiscount = (rule, value, callback) => {
  !value
    ? callback()
    : !fix1Reg.test(value)
    ? callback(new Error("请输入数字，最多保留一位小数"))
    : value > 9.9
    ? callback(new Error("输入的数字不能大于9.9"))
    : value < 0.1
    ? callback(new Error("输入的数字不能小于0.1"))
    : callback();
};

// 匹配秒数，0.1至60，可保留一位小数 - 灯光渐变用
export const checkTransitionTime = (rule, value, callback) => {
  !value
    ? callback()
    : !fix1Reg.test(value)
    ? callback(new Error("请输入数字，最多保留一位小数"))
    : value > 60000
    ? callback(new Error("输入的数字不能大于60"))
    : value < 100
    ? callback(new Error("输入的数字不能小于0.1"))
    : callback();
};

// 匹配正整数(不含0)
export const checkNumberReg = (rule, value, callback) => {
  value && !numberReg.test(value)
    ? callback(new Error("请输入正整数"))
    : callback();
};

// 匹配数字，只能为0或正整数(含0)
export const checkIntegerNumber = (rule, value, callback) => {
  value && !integerReg.test(value)
    ? callback(new Error("请输入0或正整数"))
    : callback();
};

// 匹配英文字母单词
export const checkEn = (rule, value, callback) => {
  if (value && !wordReg.test(value)) {
    callback(new Error("请输入英文词组"));
  } else {
    callback();
  }
};
// 匹配英文字母或数字
export const checkEnOrNumber = (rule, value, callback) => {
  if (value && !enOrNumberReg.test(value)) {
    callback(new Error("请输入英文或数字的组合"));
  } else {
    callback();
  }
};

/**
 * @name: 级联菜单溯源
 * @author: 易远胜
 * @description: 级联、树结构通过最后一个选中id寻找整条完整链路，追根溯源
 * @param {Array} list 所有源数据列表
 * @param {Number,String} id 需要溯源的目标id
 * @return {Array} 选中数据集合 ["1374645175883608065", "1374651936669642754", "1376370924547313666"]
 */
export function cascaderSource(list, id) {
  const arr = [];
  let item = list;

  function sale(list, id) {
    for (let i in list) {
      if (list[i].id === id) {
        arr.unshift(list[i].id);
        if (list[i].parentId) {
          sale(item, list[i].parentId);
        }
      }
      if (list[i].children && list[i].children.length) {
        sale(list[i].children, id);
      }
    }
  }

  sale(list, id);
  return arr;
}

/**
 * @name: 日期时间格式化,
 * @author: 易远胜
 * @param: {Number,String} date-时间戳或字符串时间2020-12-12 12:12:12
 * @param: {String} format-YYYY-MM-DD、YYYY-MM、YYYY-MM-DD hh:mm:ss、YYYY-MM-DD hh:mm、hh:mm:ss、hh:mm 或week(星期几)-不区分大小写
 * @return {String} 时间 2020-12-12 12:12:12 默认返回YYYY-MM-DD
 * **/
export function dateFormat(date, format) {
  if (!date) return "";
  let dateReg = /^-?[0-9]\d*$/;
  if (dateReg.test(date)) {
    date = new Date(parseInt(date));
  } else if (typeof date === "string" && "Invalid Date" != new Date(date)) {
    date = new Date(Date.parse(date.replace(/-/g, "/")));
  } else {
    return date;
  }

  function two(val) {
    if (val < 10) val = "0" + val;
    return val;
  }

  let year = date.getFullYear();
  let month = two(date.getMonth() + 1);
  let day = two(date.getDate());
  let hour = two(date.getHours());
  let minute = two(date.getMinutes());
  let second = two(date.getSeconds());
  let week = date.getDay();

  let reg1 = /^YYYY-MM-DD$/gi;
  let reg2 = /^YYYY-MM$/gi;
  let reg3 = /^YYYY-MM-DD hh:mm:ss$/gi;
  let reg4 = /^YYYY-MM-DD hh:mm$/gi;
  let reg5 = /^hh:mm:ss$/gi;
  let reg6 = /^hh:mm$/gi;
  let reg7 = /^week$/gi;
  let reg8 = /^MM-DD$/gi;
  let reg9 = /^M/gi;
  if (!format) return `${year}-${month}-${day}`;
  if (reg1.test(format)) {
    return `${year}-${month}-${day}`;
  } else if (reg2.test(format)) {
    return `${year}-${month}`;
  } else if (reg3.test(format)) {
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  } else if (reg4.test(format)) {
    return `${year}-${month}-${day} ${hour}:${minute}`;
  } else if (reg5.test(format)) {
    return `${hour}:${minute}:${second}`;
  } else if (reg6.test(format)) {
    return `${hour}:${minute}`;
  } else if (reg7.test(format)) {
    const weeks = {
      0: "星期天",
      1: "星期一",
      2: "星期二",
      3: "星期三",
      4: "星期四",
      5: "星期五",
      6: "星期六",
    };
    return `${weeks[week]}`;
  } else if (reg8.test(format)) {
    return `${month}-${day}`;
  } else if (reg9.test(format)) {
    return `${date.getMonth() + 1}月`;
  } else {
    return "";
  }
}

/**
 * @name: 金钱格式化,
 * @author: 易远胜
 * @param: [String, Number] value-需要格式化的价格
 * @param: {String} unit-单位，默认¥
 * @return String 如 ¥ 888
 * **/
export const moneyFormat = (value, unit = "¥") => {
  let money = Number(value);
  if (isNaN(money)) return value;
  money = money / 100; // 统一的分转为元
  // 分开小数和整数
  let valueArray = money.toString().split(".");
  // 整数部分
  let intStr = valueArray[0].toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
  // =2表示数据有小数位
  if (valueArray.length === 2) {
    let float = valueArray[1]; //小数部分
    if (float.length === 1) {
      // 补0,实际上用不着
      money = `${intStr}.${valueArray[1]}0`;
    } else {
      money = `${intStr}.${valueArray[1]}`;
    }
  } else {
    money = intStr + ".00";
  }
  return `${unit} ${money}`;
};

/**
 * @name: 手机号加****，安全展示,
 * @author: 易远胜
 * @param: [String, Number] value-需要处理的手机号
 * @return String 如 138****8591
 * **/
export const phoneFormat = (value) => {
  if (value && value.length === 11) {
    value = value.substr(0, 3) + "****" + value.substr(7);
  }
  return value;
};

/**
 * @name: 验证身份证*
 * @author: 易远胜
 * @params: { Number } idcard 身份证号码
 * @return { String } ErrorMessage || ""
 * */
export function isIdcard(idcard) {
  if (!idcard) return "身份证号码为空";
  var Errors = new Array(
    "", // 验证通过!
    "身份证号码位数不对!",
    "身份证号码出生日期超出范围或含有非法字符!",
    "身份证号码校验错误!",
    "身份证地区非法!"
  );
  var area = {
    11: "北京",
    12: "天津",
    13: "河北",
    14: "山西",
    15: "内蒙古",
    21: "辽宁",
    22: "吉林",
    23: "黑龙江",
    31: "上海",
    32: "江苏",
    33: "浙江",
    34: "安徽",
    35: "福建",
    36: "江西",
    37: "山东",
    41: "河南",
    42: "湖北",
    43: "湖南",
    44: "广东",
    45: "广西",
    46: "海南",
    50: "重庆",
    51: "四川",
    52: "贵州",
    53: "云南",
    54: "西藏",
    61: "陕西",
    62: "甘肃",
    63: "青海",
    64: "宁夏",
    65: "新疆",
    71: "台湾",
    81: "香港",
    82: "澳门",
    83: "台湾", // 特殊身份证
    91: "国外",
  };

  var Y, JYM;
  var S, M, ereg;
  var idcard_array = new Array();
  idcard_array = idcard.split("");
  // 地区检验
  if (area[parseInt(idcard.substr(0, 2))] == null) return Errors[4];
  // 身份号码位数及格式检验
  switch (idcard.length) {
    // 18位身份号码检测
    case 18:
      if (
        parseInt(idcard.substr(6, 4)) % 4 == 0 ||
        (parseInt(idcard.substr(6, 4)) % 100 == 0 &&
          parseInt(idcard.substr(6, 4)) % 4 == 0)
      ) {
        ereg =
          /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}[0-9Xx]$/; //闰年出生日期的合法性正则表达式
      } else {
        ereg =
          /^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|1[0-9]|2[0-8]))[0-9]{3}[0-9Xx]$/; //平年出生日期的合法性正则表达式
      }
      if (ereg.test(idcard)) {
        // 测试出生日期的合法性
        // 计算校验位
        S =
          (parseInt(idcard_array[0]) + parseInt(idcard_array[10])) * 7 +
          (parseInt(idcard_array[1]) + parseInt(idcard_array[11])) * 9 +
          (parseInt(idcard_array[2]) + parseInt(idcard_array[12])) * 10 +
          (parseInt(idcard_array[3]) + parseInt(idcard_array[13])) * 5 +
          (parseInt(idcard_array[4]) + parseInt(idcard_array[14])) * 8 +
          (parseInt(idcard_array[5]) + parseInt(idcard_array[15])) * 4 +
          (parseInt(idcard_array[6]) + parseInt(idcard_array[16])) * 2 +
          parseInt(idcard_array[7]) * 1 +
          parseInt(idcard_array[8]) * 6 +
          parseInt(idcard_array[9]) * 3;
        Y = S % 11;
        M = "F";
        JYM = "10X98765432";
        M = JYM.substr(Y, 1); // 判断校验位
        if (M == idcard_array[17]) return Errors[0];
        // 检测ID的校验位
        else return Errors[3];
      } else return Errors[2];
    // break;
    default:
      return Errors[1];
    // break;
  }
}

// 身份证的验证规则
export const checkIdCard = (rule, value, callback) => {
  if (value) {
    if (isIdcard(value)) {
      callback(new Error('身份证为15位数字 或 18位数字(最后一位可以是"X")'));
    } else {
      callback();
    }
  } else {
    callback();
  }
};
/**
 * @name: 获取身份证信息
 * @author: yys
 * @params: { Number } idcard 身份证号码
 * @return { Object }
 * */
export const idCardData = (idCard) => {
  var obj = {
    area: "", //籍贯
    birthday: "", //出生日期
    sex: "", //性别
    age: "", //年龄
  };
  //根据身份证号获取生日、性别、年龄
  if (idCard && isIdcard(idCard) === "") {
    //根据身份证号获取籍贯
    var areas = {
      11: "北京",
      12: "天津",
      13: "河北",
      14: "山西",
      15: "内蒙古",
      21: "辽宁",
      22: "吉林",
      23: "黑龙江",
      31: "上海",
      32: "江苏",
      33: "浙江",
      34: "安徽",
      35: "福建",
      36: "江西",
      37: "山东",
      41: "河南",
      42: "湖北",
      43: "湖南",
      44: "广东",
      45: "广西",
      46: "海南",
      50: "重庆",
      51: "四川",
      52: "贵州",
      53: "云南",
      54: "西藏",
      61: "陕西",
      62: "甘肃",
      63: "青海",
      64: "宁夏",
      65: "新疆",
      71: "台湾",
      81: "香港",
      82: "澳门",
      83: "台湾", // 特殊
    };
    obj.area = areas[parseInt(idCard.substring(0, 2))];
    //根据身份证号获取出生日期
    obj.birthday =
      idCard.substring(6, 10) +
      "-" +
      idCard.substring(10, 12) +
      "-" +
      idCard.substring(12, 14);
    //根据身份证号获取性别
    if (parseInt(idCard.substr(16, 1)) % 2 == 1) {
      obj.sex = "男";
    } else {
      obj.sex = "女";
    }
    //根据身份证号获取年龄
    obj.age = new Date().getFullYear() - idCard.substring(6, 10) - 1;
  }
  return obj;
};

/**
 * 通过blob，下载后端返回的二进制文件。
 * @param {data} data:通过axios里的loadFile方法返回的接口数据
 */
export function loadBinaryFile(data) {
  const fileType = data.resHeader["content-type"];
  const contentDisposition = data.resHeader["content-disposition"];
  // 'attachment;filename=%E8%A1%A8.xlsx' => '%E8%A1%A8.xlsx'
  const soureName = contentDisposition.split(";")[1].split("=")[1];
  // '%E8%A1%A8.xlsx' => '表.xlsx'
  const fileName = window.decodeURIComponent(soureName);
  const blobFile = new Blob([data.blob], { type: fileType });
  const a = document.createElement("a");
  a.href = window.URL.createObjectURL(blobFile);
  a.download = fileName; // 下载保存显示的文件名
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(a.href);
}

/**
 *@name: 根据地址栏pathname获取当前平台
 *@description: 为了兼容后台在同一个浏览器可以打开多个平台需要区分
 *@description:
 *@date: 2022-09-23
 *@author: 易远胜
 *@return: { String } 当前平台下的编号，没有匹配则返回undefined
 */
export function getThisChannel() {
  const obj = {
    // "/": "1", // 运营平台
    "/": "4", // 运营平台 （临时处理）
    "/index.html": "1", // 运营平台
    "/alot.html": "4", // AloT 智控云平台
  };

  return obj[location.pathname];
}

/**
 *@name: 根据地址栏pathname获取当前平台的token
 *@description: 为了兼容后台在同一个浏览器可以打开多个渠道的平台，需要区分不同的token
 *@date: 2022-09-23
 *@author: 易远胜
 *@return: { String } 当前平台下的token
 */
export function getThisToken() {
  return localStorage.getItem(`token${getThisChannel()}`) || "";
}

/**
 *@name: 所有表单价格转换，适用于多个价格场景，一两个建议直接手动转换，效率高
 *@description: 对所有表单进行统一的价格单位转换 分 <=> 元，如果传入reversal:false, 则是把分转为元， 否则元转为分
 *              如：const ruleForm = { money: 12, name: 张三, data: { count: 3, price: 5} }
 *                 transform(ruleFrom, ["money", "price"])
 *                 console.log(ruleForm) // { money: 1200, name: 张三, data: { count: 3, price: 500} }
 *@date: 2022-09-22
 *@author: 易远胜
 *@params: { Object } ruleForm 需要转换的对象
 *@params: { Array } ruleForm 需要转换的字段集合[]
 *@params: { Boolean } reversal true分转为元，false 元转为分
 *@params: { Boolean } copy 是否复制ruleForm 默认为 true,
 *@return: { Object } obj 转换后的对象
 */

// 转换价格
export function transformForm(ruleForm, arr, reversal = false, copy = true) {
  // 如果是首次转换，则复制一个对象，后续返回，否则递归则该对象，直接修改对象的值
  const obj = copy ? JSON.parse(JSON.stringify(ruleForm || [])) : ruleForm;
  const fn = (key, value) => {
    // 是数组 或 对象
    if (value && typeof value === "object") {
      transformForm(value, arr, reversal, false);
    } else if (!isNaN(value) && value !== "" && arr.includes(key)) {
      // 分 <=> 元
      let unit = reversal ? 1 : 10000;
      obj[key] = Math.round(value * unit) / 100;
    }
  };
  // 是否是数组
  if (Array.isArray(obj)) {
    obj.forEach((item, index) => {
      fn(index, item);
    });
  } else {
    for (let key in obj) {
      fn(key, obj[key]);
    }
  }
  return obj;
}

/**
 *@name: 把列表转换成树结构/级联
 *@date: 2022-09-22
 *@author: 易远胜
 *@params: { Array } list //需要转换的列表
 *@return: { Array } tree //转换后的列表（树结构/级联）
 */

export function listToTree(list, key = "parentId") {
  // 先把列表分成两份，首级和其它级别
  const tree = []; // 一级
  const children = []; // 其它级别
  list.forEach((item) => {
    if (item[key] === 0) tree.push(item);
    else children.push(item);
  });

  classify(tree);

  // 从第一级逐级递归分类
  function classify(arr) {
    arr.forEach((item) => {
      item.children = [];
      children.forEach((temp) => {
        if (item.id === temp[key]) {
          item.children.push(temp);
        }
      });
      // 如果有子级，则往下递归收集子集合
      if (item.children.length) classify(item.children);
      // 否则删除子级
      else delete item.children;
    });
  }

  return tree;
}

/**
 * @name: 表单校验不通过提示
 * */
export function formErrorMessage() {
  Message.error("表单校验不通过，请检查！");
}
