<template>
  <el-dialog
    title="空调预设"
    width="320px"
    :visible="visible"
    :close-on-click-modal="false"
    @close="close"
    @open="open"
  >
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="50px">
      <el-form-item label="模式" prop="mode">
        <el-radio-group v-model="ruleForm.mode">
          <el-radio-button label="cold">制冷</el-radio-button>
          <el-radio-button label="heat">制暖</el-radio-button>
          <el-radio-button label="wind">送风</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="风速" prop="speed">
        <el-radio-group v-model="ruleForm.speed">
          <el-radio-button label="auto">自动</el-radio-button>
          <el-radio-button label="low">低速</el-radio-button>
          <el-radio-button label="middle">中速</el-radio-button>
          <el-radio-button label="high">高速</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="温度" prop="temperature">
        <el-input-number
          v-model="ruleForm.temperature"
          :min="16"
          :max="30"
          label="请输入温度"
          @change="temperatureChange"
        ></el-input-number>
      </el-form-item>
    </el-form>

    <footer slot="footer">
      <el-button size="large" @click="close">取消</el-button>
      <el-button type="primary" size="large" @click="sure">确定</el-button>
    </footer>
  </el-dialog>
</template>

<script>
import { cloudPreSettingAirGet, cloudPreSettingAirPost } from "../../../../api";
export default {
  name: "ControlsPreset",

  props: {
    visible: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      ruleForm: {
        mode: "", // cold -> 制冷 heat -> 制热 wind -> 送风
        speed: "", //  low -> 低风速 middle -> 中风速 high -> 高风速 auto -> 自动
        temperature: 26, // 预设温度
      },
      rules: {
        mode: [{ required: true, message: "请选择模式", trigger: "change" }],
        speed: [{ required: true, message: "请选择风速", trigger: "change" }],
        temperature: [
          { required: true, message: "请输入温度", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              value && !/^[1-9]\d*$/.test(value)
                ? callback(new Error("请输入正整数"))
                : callback();
            },
            trigger: "blur",
          },
        ],
      },
    };
  },

  created() {},

  methods: {
    close() {
      this.$emit("update:visible", false);
    },

    // 打开
    open() {
      cloudPreSettingAirGet()
        .then(({ data }) => {
          if (data.data) {
            this.ruleForm.mode = data.data.mode;
            this.ruleForm.speed = data.data.speed;
            this.ruleForm.temperature = data.data.temperature;
          }
        })
        .catch((e) => console.error(e));
    },

    // 温度改变
    temperatureChange(val) {
      if (val === 15) {
        this.ruleForm.temperature = 0;
      } else if (val === 1) {
        this.ruleForm.temperature = 16;
      } else if (val < 16 && val > 0) {
        this.ruleForm.temperature = 16;
      } else {
        this.ruleForm.temperature = val;
      }
    },

    // 确定
    sure() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          cloudPreSettingAirPost(this.ruleForm)
            .then(() => {
              this.$message.success("保存成功");
              this.close();
            })
            .catch(() => {});
        } else {
          this.$message.error("表单校验不通过，请检查");
        }
      });
    },
  },
};
</script>
<style scoped></style>
