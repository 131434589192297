var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "dialog" } }, [
    _vm.visible
      ? _c("div", { staticClass: "dialog", class: [_vm.theme] }, [
          _c(
            "header",
            { staticClass: "flex-between top" },
            [
              _vm._t("header", function () {
                return [_c("h2", [_vm._v(_vm._s(_vm.title))])]
              }),
              _c("i", {
                staticClass: "el-icon-circle-close font-xxxl pointer",
                on: { click: _vm.close },
              }),
            ],
            2
          ),
          _c("section", { staticClass: "body" }, [_vm._t("default")], 2),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }