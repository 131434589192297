var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loaded
    ? _c("RoomControlAir", {
        attrs: { data: _vm.ruleForm, "auto-submit": false },
        on: { change: _vm.change },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }