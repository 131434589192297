<template>
  <el-dialog
    :visible="visible"
    title="手动入住/退房"
    @close="close"
    :close-on-click-modal="false"
    width="650px"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      :inline="true"
      ref="ruleForm"
      label-width="90px"
      class="demo-ruleForm"
    >
      <el-form-item label="入住房间" prop="roomNo">
        <el-input
          v-model="ruleForm.roomNo"
          clearable
          class="input"
          placeholder="请输入房间号"
        ></el-input>
      </el-form-item>
      <el-form-item label="操作类型" prop="status" required>
        <el-radio v-model="ruleForm.status" label="checkin" @change="nameChange"
          >入住</el-radio
        >
        <el-radio
          v-model="ruleForm.status"
          label="checkout"
          @change="nameChange"
          >退房</el-radio
        >
      </el-form-item>
      <el-form-item label="客人姓名" prop="name">
        <el-input
          v-model="ruleForm.name"
          clearable
          class="input"
          disabled
          placeholder="请输入姓名"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <el-input
          v-model="ruleForm.phone"
          class="input"
          clearable
          placeholder="请输入手机号"
        ></el-input>
      </el-form-item>
      <el-form-item label="入住时间" prop="arr">
        <el-date-picker
          class="input"
          v-model="ruleForm.arr"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="退房时间" prop="dep">
        <el-date-picker
          class="input"
          v-model="ruleForm.dep"
          type="datetime"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>

    <footer class="flex-between" slot="footer">
      <p class="gray">注：此功能仅在PMS数据同步异常时（紧急情况下）操作</p>
      <div>
        <el-button size="large" @click="close">取消</el-button>
        <el-button type="primary" size="large" @click="submit">确定</el-button>
      </div>
    </footer>
  </el-dialog>
</template>

<script>
import { checkEnOrNumber, checkPhone } from "@/utils/global.js";
import { biHotelPms } from "../../../../api";
import moment from "moment";
export default {
  name: "HandInOut",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ruleForm: {
        roomNo: "",
        status: "checkin",
        phone: "",
        name: "手动入住",
        arr: "",
        dep: "",
      },
      rules: {
        roomNo: [
          { required: true, message: "请输入房间号", trigger: "blur" },
          { validator: checkEnOrNumber, trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: checkPhone, trigger: "blur" },
        ],
        arr: [{ required: true, message: "请选择入住时间", trigger: "change" }],
        dep: [{ required: true, message: "请选择退房时间", trigger: "change" }],
      },
    };
  },

  created() {
    const now = moment().startOf("date");
    this.ruleForm.arr = now.format("YYYY-MM-DD HH:mm:ss");
    const tomorrow = now.add(1, "d").add(14, "h");
    this.ruleForm.dep = tomorrow.format("YYYY-MM-DD HH:mm:ss");
  },

  methods: {
    nameChange(val) {
      if (val === "checkin") {
        this.ruleForm.name = "手动入住";
      } else {
        this.ruleForm.name = "手动退房";
      }
    },
    close() {
      this.$emit("update:visible", false);
    },

    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          biHotelPms(this.ruleForm)
            .then(({ data }) => {
              if (data.code === "000000") {
                this.$message.success("保存成功");
                this.close();
                this.$emit("update", true);
              }
            })
            .catch(() => {});
        } else {
          this.$message.error("表单校验不通过");
        }
      });
    },
  },
};
</script>
<style scoped>
.input {
  width: 200px;
}
</style>
