<template>
  <!-- 弹窗 -->
  <transition name="modal">
    <div class="modal" v-if="visible">
      <div class="window" :class="[theme]" :style="{ width }">
        <header class="flex-between top">
          <h2>{{ title }}</h2>
          <i class="el-icon-circle-close font-xxxl pointer" @click="cancel"></i>
        </header>

        <section class="ph-2x">
          <slot></slot>
        </section>

        <slot name="footer">
          <footer class="flex-center p-2x mt-1x">
            <el-button @click="cancel" plain size="large">取消</el-button>

            <el-button v-if="loading" type="primary" size="large" loading
              >加载中</el-button
            >
            <el-button v-else type="primary" @click="sure" size="large"
              >确定</el-button
            >
          </footer>
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "MyDialogSmall",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "30%",
    },
    theme: {
      type: String,
      default: "",
    },

    beforeClose: {
      type: [Function, Object],
      default: null,
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  created() {},

  methods: {
    sure() {
      this.$emit("sure", true);
      if (this.beforeClose) {
        this.loading = true;
        // this.beforeClose("confirm", () => {
        //   this.loading = false;
        //   this.close();
        // });

        this.beforeClose("confirm")
          .then(() => {
            this.loading = false;
            this.close();
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        this.close();
      }
    },
    cancel() {
      this.$emit("cancel", false);
      if (this.beforeClose) {
        this.beforeClose("cancel")
          .then(() => {
            this.close();
          })
          .catch(() => {
            this.close();
          });
      } else {
        this.close();
      }
    },

    close() {
      this.$emit("close", false);
      this.$emit("update:visible", false);
    },
  },
};
</script>
<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  .window {
    position: absolute;
    left: 50%;
    top: 50%;
    min-width: 300px;
    overflow: hidden;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    box-shadow: 0 1px 16px 0 rgba(0, 0, 0, 0.3);
    background: #054796;
    color: #f7f7f7;
    &.black {
      background: #1f1e23;
      color: #999;
    }
    .top {
      padding: 16px;
    }
  }
}

/* 设置持续时间和动画函数 */
.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease-out;
}
.modal-enter,
.modal-leave-to {
  margin-top: -32px;
  opacity: 0;
}

/deep/ .el-button--default {
  color: #fff;
  background: rgba(255, 255, 255, 0.05);
}

/deep/.el-input__inner {
  color: #fff;
  background: transparent;
}
</style>
