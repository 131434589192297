<template>
  <my-dialog
    :visible="visible"
    :title="currentTitle"
    theme="black"
    @close="close"
  >
    <el-row class="pb-2x">
      <el-col :xl="6" :lg="7" class="relative">
        <aside class="left">
          <components :is="currentComponent" @change="handleData"></components>

          <footer class="footer">
            <div class="mb-3x">
              <!-- 有人可控制空调 -->
              <el-checkbox v-if="roomHumanControl" v-model="someOneCanControl">
                <span class="white">有人可控制空调</span>
              </el-checkbox>

              <el-checkbox v-model="checked" @change="checkAll">
                <span class="white">全选所有可控房间</span>
              </el-checkbox>
            </div>
            <el-button size="large" icon="el-icon-close" @click="close"
              >取消</el-button
            >
            <el-button
              type="primary"
              size="large"
              icon="el-icon-folder-checked"
              @click="sure"
              >确定</el-button
            >
          </footer>
        </aside>
      </el-col>
      <el-col :xl="18" :lg="17">
        <div class="right">
          <floor-room-item
            ref="floorRoomItem"
            multiple
            :list="buildingData"
            :some-one-can-control="someOneCanControl"
            theme="black"
            @checked-all="checkedAll"
            @change="handleRooms"
          ></floor-room-item>
        </div>
      </el-col>
    </el-row>
  </my-dialog>
</template>

<script>
import MyDialog from "@/components/page/control/components/MyDialog";
import FloorRoomItem from "@/components/page/control/components/FloorRoomItem";
import ControlsAir from "@/components/page/control/components/ControlsAir";
import ControlsCurtain from "@/components/page/control/components/ControlsCurtain";
import ControlsAeration from "@/components/page/control/components/ControlsAeration";
import { cloudGroupControl } from "../../../../api";
export default {
  name: "ControlsDialog",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    buildingData: {
      type: Array,
      default() {
        return [];
      },
    },
    componentName: {
      type: String,
      required: true,
    },
  },

  components: {
    MyDialog,
    FloorRoomItem,
  },

  data() {
    return {
      elements: {
        controlsAir: {
          name: "空调集控",
          component: ControlsAir,
        },
        controlsCurtain: {
          name: "窗帘集控",
          component: ControlsCurtain,
        },
        controlsAeration: {
          name: "换气集控",
          component: ControlsAeration,
        },
      },

      checked: false,
      checkedRooms: [],
      controlData: {},

      someOneCanControl: false,
    };
  },

  computed: {
    element() {
      return this.elements[this.componentName];
    },

    currentComponent() {
      return this.element.component;
    },
    currentTitle() {
      return this.element.name;
    },
    // 有人运行控制空调
    roomHumanControl() {
      return (
        this.componentName === "controlsAir" &&
        this.$store.state.roleMenu.includes("room:human:control")
      );
    },
  },

  created() {},

  methods: {
    close() {
      this.checked = false;
      this.someOneCanControl = false;
      this.$emit("update:visible", false);
    },
    // 全选
    checkAll(checked) {
      let checks = this.$refs.floorRoomItem.checkedAll(checked);
      if (checked) {
        if (checks === 0) {
          this.checked = false;
          this.$message.error("没有可控房间");
        } else {
          this.$message.info(`已选中${checks}间`);
        }
      }
    },
    // 全选
    checkedAll(checked) {
      this.checked = checked;
    },
    // 选择房间
    handleRooms(rooms) {
      this.checkedRooms = rooms;
    },
    // 控制数据
    handleData(data) {
      this.controlData = data;
    },

    // 确定
    sure() {
      if (!this.checkedRooms.length) {
        this.$message.error("请选择房间");
      } else {
        const data = {
          gatewayUuid: this.checkedRooms.map((room) => room.gatewayUuid),
          ...this.controlData,
        };
        // 是否允许强制控制
        if (this.roomHumanControl) {
          data.force = Number(this.someOneCanControl);
        }
        cloudGroupControl(data)
          .then(() => {
            this.$message.success("控制成功");
            this.close();
            this.$emit("change", this.controlData.deviceType);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.left,
.right {
  height: calc(100vh - 80px);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  overflow-y: auto;
  // 定义滚动条的底背景及宽高
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: transparent;
  }

  // 定义滚动条滑块
  &::-webkit-scrollbar-thumb {
    border-radius: 16px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;

    &:hover {
      background-color: #fff;
    }
  }
}

.left {
  position: relative;
  margin-right: 16px;
  background: #1f1e23;

  .footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-bottom: 16px;
    text-align: center;
  }
}
</style>
