<template>
  <div class="bg">
    <!-- 头部 -->
    <header class="top flex-start">
      <the-header
        :playing="playing"
        :muted="muted"
        @play="play"
        @pause="pause"
        @open-command="openCommand"
        @room-search="roomSearch"
        @update="loadChartData"
      ></the-header>
    </header>

    <!-- body -->
    <main class="flex-evenly main">
      <!-- 左 -->
      <aside class="left">
        <div class="top box">
          <seasonal-pattern
            ref="seasonalPattern"
            @update="loadChartData"
          ></seasonal-pattern>
        </div>
        <div class="bottom box">
          <room-message
            ref="roomMessage"
            @play="play"
            @pause="pause"
          ></room-message>
        </div>
      </aside>
      <!-- 右 -->
      <section class="right">
        <div class="buildings">
          <floor-room-item
            :list="buildingData"
            @open="roomMonitoring"
          ></floor-room-item>
          <!-- 暂无数据 -->
          <el-empty v-if="buildingData.length === 0"></el-empty>
        </div>
      </section>
    </main>

    <!-- 开空调 -->
    <my-dialog :visible.sync="visibleAir" title="开空调">
      <floor-room-item :list="airData" @open="controlAir"></floor-room-item>
    </my-dialog>

    <!-- 客房查询、客房控制弹窗 -->
    <room-control
      :visible.sync="visibleRoomControl"
      :room="tempRoom"
      :room-no="roomNo"
      @close="loadChartData"
    ></room-control>

    <!-- 空调控制弹窗 -->
    <my-dialog-small
      :visible.sync="visibleAirControl"
      width="500px"
      theme="black"
      :title="tempRoom.roomNo"
      @cancel="loadChartData"
    >
      <room-control-air
        v-for="item in airs"
        :key="item.deviceId"
        :data="item"
        :response="false"
      ></room-control-air>

      <footer slot="footer"></footer>
    </my-dialog-small>

    <!-- 授权播放音频 -->
    <my-dialog-small
      title="提示"
      :visible.sync="showClickAudio"
      @cancel="openAudio"
    >
      <p>刷新页面后需重新授权开启紧急呼叫语音报警功能！</p>
      <footer slot="footer" class="flex-center pv-2x">
        <el-button type="primary" size="large" @click="openAudio"
          >授权开启</el-button
        >
      </footer>
    </my-dialog-small>
    <!-- 空调预设设置 -->
    <controls-preset :visible.sync="showControlsPreset"></controls-preset>
    <!-- 集控 -->
    <controls-dialog
      :visible.sync="showControls"
      :building-data="buildingData"
      :component-name="controlComponentName"
      @change="handleControlChange"
    ></controls-dialog>
  </div>
</template>

<script>
import TheHeader from "@/components/page/control/components/TheHeader";
import SeasonalPattern from "@/components/page/control/components/SeasonalPattern";
import RoomMessage from "@/components/page/control/components/RoomMessage";
import RoomControl from "@/components/page/control/components/RoomControl";
import MyDialog from "@/components/page/control/components/MyDialog";
import MyDialogSmall from "@/components/page/control/components/MyDialogSmall";
import FloorRoomItem from "@/components/page/control/components/FloorRoomItem";
import RoomControlAir from "@/components/page/control/components/RoomControlAir";
import ControlsPreset from "@/components/page/control/components/ControlsPreset";
import ControlsDialog from "@/components/page/control/components/ControlsDialog";

import { biHotelRoomStatus, biHotelRoomDevice } from "../../../api";
export default {
  name: "ChartIndex",

  components: {
    TheHeader,
    SeasonalPattern,
    RoomMessage,
    RoomControl,
    FloorRoomItem,
    MyDialog,
    MyDialogSmall,
    RoomControlAir,
    ControlsPreset,
    ControlsDialog,
  },

  data() {
    return {
      audio: null, // 警报播放器
      showClickAudio: false, // 授权播放语音
      playing: false, // 播放中
      muted: false,
      timer: null,
      warningData: [],
      buildingData: [], // 房间数据
      airData: [], // 可开空调房间数据
      airs: [], // 房间空调数据

      visibleRoomControl: false, // 房间控制弹窗
      roomNo: "", // 房号
      tempRoom: {}, // 选择的房间
      visibleAir: false, // 开空调弹窗
      visibleAirControl: false, //控制空调弹窗
      showControlsPreset: false, // 预设弹窗
      showControls: false, // 展示集控
      controlComponentName: "controlsAeration",
    };
  },

  computed: {
    // 用户访问权限
    userJurisdiction() {
      return this.$store.state.roleMenu;
    },
    // 用户控制权限
    canControl() {
      return this.userJurisdiction.indexOf("equipmentInfo:look") !== -1;
    },
  },

  created() {},

  mounted() {
    window.addEventListener("resize", this.windowResize);
    this.audio = document.getElementById("audio");
    if (this.audio.currentTime === 0) {
      this.showClickAudio = true;
    }
    this.loadChartData();
  },

  beforeDestroy() {
    this.clear();
    this.pause("noTip");
    window.removeEventListener("resize", this.windowResize);
  },

  methods: {
    // 开启语音警报功能
    openAudio() {
      this.audio.muted = true;
      this.audio.pause();
      this.showClickAudio = false;
      const some = this.warningData.some((item) => item.status === "sos");
      // console.log(some);
      if (some) {
        this.play();
      }
    },
    // 播放警报
    play() {
      this.playing = true;
      this.muted = false;
      this.audio.loop = true;
      this.audio.defaultPlaybackRate = 1.5;
      this.audio.load();
      this.audio.muted = false;
      this.audio.play();
    },

    // 关闭警报
    pause(param) {
      this.muted = true;
      this.audio.pause();
      if (param !== "noTip") this.$message.success("已静音");
    },
    // 窗口尺寸变化
    windowResize() {
      this.$refs.seasonalPattern.resize();
    },
    // 加载图表数据
    loadChartData() {
      this.clear();
      biHotelRoomStatus({})
        .then(({ data }) => {
          const { warningData, buildingData, mainBoardType, seasonMode } =
            data.data;
          this.warningData = warningData;
          this.buildingData = buildingData;
          this.mainBoardType = mainBoardType;
          this.$refs.roomMessage.loadData(warningData);
          // 转换季节值，兼容旧版本内容 mainBoardType：主机型号， 旧版季节类型：seasonMode
          this.$refs.seasonalPattern.getConfig(mainBoardType, seasonMode);
          if (this.visibleAir) {
            this.openAir();
          }
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.timer = setTimeout(() => {
            this.loadChartData();
          }, 10000);
        });
    },

    // 打开房间控制
    openControl() {
      if (this.canControl) {
        this.visibleRoomControl = true;
      } else {
        this.$message({
          type: "error",
          message: "无权访问，请联系管理员！",
        });
      }
    },
    // 客控工具打开
    openCommand(command) {
      if (command === "showControlsPreset") {
        this.showControlsPreset = true;
      } else {
        this.controlComponentName = command;
        this.showControls = true;
      }
    },
    // 客房查询
    roomSearch(number) {
      this.roomNo = number;
      this.openControl();
    },
    // 客房控制
    roomMonitoring(room) {
      this.roomNo = "";
      this.tempRoom = room;
      this.openControl();
    },
    // 开空调
    openAir() {
      this.airData = this.buildingData.map((item) => {
        const floorData = [];
        item.floorData.forEach((temp) => {
          const roomData = temp.roomData.filter(
            (room) => room.basicstatus === "on" && room.identity === "noPeople"
          );
          if (roomData.length > 0) {
            floorData.push({ floorName: temp.floorName, roomData });
          }
        });

        return {
          buildingName: item.buildingName,
          floorData,
        };
      });
      this.visibleAir = true;
    },
    // 空调弹窗
    controlAir(room) {
      this.tempRoom = room;
      this.visibleAirControl = true;
      biHotelRoomDevice({ gatewayUuid: room.gatewayUuid })
        .then((res) => {
          this.airs = res.data.data.air;
        })
        .catch(() => {});
    },
    // 清除定时器
    clear() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    // 集控空调，刷新列表
    handleControlChange(type) {
      if (type === "aircondition") {
        this.loadChartData();
      }
    },
  },
};
</script>
<style lang="scss">
@import "../../../assets/css/common";
</style>

<style scoped lang="scss">
.border-box {
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 16px;
  &:hover {
    border-color: rgba(255, 255, 255, 0.5);
  }
  overflow: hidden;
}

.bg {
  background: #054796;
  // background: #1f1e23;
  font-size: 14px;
  color: #f7f7f7;

  // 头部
  > .top {
    height: 7vh;
    width: 100%;
    box-sizing: border-box;
    padding: 2vh 1.5vw 0;
  }

  // body
  .main {
    width: 100%;
    height: 93vh;
    overflow: hidden;

    .left {
      width: 20.5%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      .top {
        width: 100%;
        height: 33%;
        @extend .border-box;
      }

      .bottom {
        height: 60%;
        width: 100%;
        @extend .border-box;
      }
    }

    .right {
      width: 76%;
      height: 95%;
      @extend .border-box;

      .buildings {
        height: 100%;
        overflow-y: auto;
        // 定义滚动条的底背景及宽高
        &::-webkit-scrollbar {
          width: 4px;
          height: 4px;
          background-color: transparent;
        }

        // 定义滚动条滑块
        &::-webkit-scrollbar-thumb {
          border-radius: 16px;
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
          background-color: #409eff;

          &:hover {
            background-color: #fff;
          }
        }
      }
    }
  }

  // 适配
  @media screen and (max-width: 1600px) {
    .main {
      .left {
        width: 20%;
      }
      .right {
        width: 76%;
      }
    }
  }
  @media screen and (max-width: 1500px) {
    .main {
      .left {
        width: 24%;
      }

      .right {
        width: 73%;
      }
    }
  }
}
</style>
