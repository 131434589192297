<template>
  <transition name="dialog">
    <div v-if="visible" class="dialog" :class="[theme]">
      <header class="flex-between top">
        <slot name="header">
          <h2>{{ title }}</h2>
        </slot>
        <i class="el-icon-circle-close font-xxxl pointer" @click="close"></i>
      </header>

      <section class="body">
        <slot></slot>
      </section>
    </div>
  </transition>
</template>

<script>
export default {
  name: "MyDialog",

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    theme: {
      type: String,
      default: "primary",
    },
  },

  watch: {
    visible(val) {
      if (val) {
        // document.body.appendChild(this.$el);
        this.$emit("open", true);
      }
    },
  },

  data() {
    return {};
  },

  created() {},

  // destroyed() {
  //   if (this.$el && this.$el.parentNode) {
  //     this.$el.parentNode.removeChild(this.$el);
  //   }
  // },

  methods: {
    close() {
      this.$emit("close", false);
      this.$emit("update:visible", false);
    },
  },
};
</script>
<style scoped lang="scss">
.dialog {
  position: fixed;
  padding: 64px 0 16px;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  background: #054796;
  color: #f7f7f7;
  z-index: 99;

  &.black {
    background: #1f1e23;
    color: #999;
  }

  .top {
    padding: 16px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .body {
    height: calc(100vh - 64px);
    padding: 0 16px;
    overflow: auto;
  }
}

/* 可以设置不同的进入和离开动画 */
/* 设置持续时间和动画函数 */
.dialog-enter-active,
.dialog-leave-active {
  transition: all 0.3s ease-out;
}
.dialog-enter,
.dialog-leave-to {
  top: 100vh;
  opacity: 0;
}
</style>
