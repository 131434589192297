var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "device flex-center" }, [
    _c("div", { staticClass: "text-c" }, [
      _vm.switchstate === "off"
        ? _c("img", {
            staticClass: "curtain-img",
            attrs: { src: require("../../../../assets/img/curtain-close.svg") },
          })
        : _c("img", {
            staticClass: "curtain-img",
            attrs: { src: require("../../../../assets/img/curtain-open.svg") },
          }),
      _c("p", { staticClass: "font-l mv-4x" }, [_vm._v("窗帘")]),
      _c("div", { staticClass: "flex-evenly" }, [
        _c(
          "div",
          {
            attrs: { title: "打开" },
            on: {
              click: function ($event) {
                return _vm.toggleCurtain("on")
              },
            },
          },
          [
            _vm.switchstate === "on"
              ? _c("img", {
                  staticClass: "curtain-btn",
                  attrs: {
                    src: require("../../../../assets/img/on-active.svg"),
                  },
                })
              : _c("img", {
                  staticClass: "curtain-btn",
                  attrs: { src: require("../../../../assets/img/on.svg") },
                }),
          ]
        ),
        _c(
          "div",
          {
            attrs: { title: "暂停" },
            on: {
              click: function ($event) {
                return _vm.toggleCurtain("pause")
              },
            },
          },
          [
            _vm.switchstate === "pause"
              ? _c("img", {
                  staticClass: "curtain-btn",
                  attrs: {
                    src: require("../../../../assets/img/pause-active.svg"),
                  },
                })
              : _c("img", {
                  staticClass: "curtain-btn",
                  attrs: { src: require("../../../../assets/img/pause.svg") },
                }),
          ]
        ),
        _c(
          "div",
          {
            attrs: { title: "关闭" },
            on: {
              click: function ($event) {
                return _vm.toggleCurtain("off")
              },
            },
          },
          [
            _vm.switchstate === "off"
              ? _c("img", {
                  staticClass: "curtain-btn",
                  attrs: {
                    src: require("../../../../assets/img/off-active.svg"),
                  },
                })
              : _c("img", {
                  staticClass: "curtain-btn",
                  attrs: { src: require("../../../../assets/img/off.svg") },
                }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }